import React from 'react'
import {NavLink} from 'react-router-dom'

import LinkedInLogo from '../assests/img/linkedin.png'
import GithubLogo from '../assests/img/github.png'
import EmailLogo from '../assests/img/send-email.png'
import KsLogo from '../assests/img/ksLogo.png'
import '../assests/css/Header.css'



const Header = () => {


    return (
        <div className='header-ctr'>
              <NavLink exact to='/'><div className='Ks-logo'>

        <img className='kslogo' src={KsLogo} alt='Kim Semenza Logo'/>
        </div>    
        </NavLink>

<div className='nav-link-wrap'>
        {/* start of nav btn */}
       <NavLink  className='nav-link' exact activeClassName='link-active' to='/'>Home</NavLink>  
                 <NavLink className='nav-link' exact activeClassName='link-active' to='/about'>Skills</NavLink>  
                 <NavLink className='nav-link' exact activeClassName='link-active' to='/projects'>Projects</NavLink>  
                 <NavLink className='nav-link' exact to='/contact' activeClassName='link-active'> Contact </NavLink>
                

        {/* end of nav btn */}
        </div>
        {/* end of nav wrap */}

        {/* start of social icons wrap */}
        <div className='social-links-wrap'>
        <a href='https://www.linkedin.com/in/kim-semenza-2b4961199/' target='blank'> 
            <img className='nav-icon linkedin' src={LinkedInLogo} alt='LinkedIn Icon'/></a>
        <a href='https://github.com/ksemenza?tab=repositories' target='blank'>
            <img className='nav-icon github' src={GithubLogo} alt='Github Icon'/></a>
            <a href='mailto:ksemenza.247@gmail.com'> 
            <img className='nav-icon email' src={EmailLogo} alt=' Icon'/></a>
        </div>
        {/* end of social link wrap */}
        </div>
        // end of ctr
    )
}

export default Header