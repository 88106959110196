import React from 'react'
import Resume from './Resume'
import LinkedInLogo from '../assests/img/linkedin.png'
import GithubLogo from '../assests/img/github.png'
import EmailLogo from '../assests/img/send-email.png'
import '../assests/css/HomePage.css'
import Quotes from '../utlis/quoteGenerator'

const HomePage = () => {

    const newQuoteClick = () => {
        window.location.reload()
    }

    const moreInfoClick = (e) => {
        e.preventDefault()
        let infoModal = document.querySelector('.info-modal')
        infoModal.classList.toggle('info-modal--open')
    }
    

   

    return (
        <div  className='home-bg-img'>
        <div className='homepage-ctr'>
            <div className='name-title-wrap'>
            <h1>Kim Semenza</h1>
            
            <h3 className='dev-title'>Full-Stack Web Developer</h3>
            </div>



            <div className='quote-btn-ctr'>
                <div className='btn-wrap'>
                <button onClick={moreInfoClick}>About me</button>
                <button className='quote-btn' onClick={newQuoteClick}>New quote</button>
                {/* <Resume/> */}
                </div>
            <div className='quote-ctr'>
            <Quotes/>
            </div>
            </div>

                    {/* start of social icons wrap */}
        <div className='social-links-home'>
        <a href='https://www.linkedin.com/in/kim-semenza-2b4961199/' target='blank'> 
            <img className='nav-icon linkedin' src={LinkedInLogo} alt='LinkedIn Icon'/></a>
        <a href='https://github.com/ksemenza?tab=repositories' target='blank'>
            <img className='nav-icon github' src={GithubLogo} alt='Github Icon'/></a>
            <a href='mailto:ksemenza.247@gmail.com'> 
            <img className='nav-icon email' src={EmailLogo} alt=' Icon'/></a>
        </div>
        {/* end of social link wrap */}

            <div className='info-modal'>
                {/* <div className='modal-btn-title-ctr'> */}
                <button className='close-btn' onClick={moreInfoClick}> X </button>
                {/* </div> */}

             <Resume/>

            </div>

        </div>
        </div>
        // end of ctr
    )
}

export default HomePage