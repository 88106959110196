import React from 'react';
import {Route, Switch} from "react-router-dom";
import HomePage from './components/Homepage'
import About from './components/About'
import Projects from './components/Projects'
import Contact from './components/Contact'
import './assests/css/App.css';



function App() {
  return (
    <div className="App">
      <header className="App-header">
      <Switch>   
            <Route path exact='/' component={HomePage}/>
            <Route path='/about' component={About}/>
            <Route path='/projects' component={Projects}/>
            <Route path='/contact' component={Contact}/>
            </Switch>
      </header>
    </div>
  );
}

export default App;
