import React from 'react'
import '../assests/css/Footer.css'
const Footer = () => {


    return (
      
        <div className='footer-ctr'>
            <p>   Guin Dev Productions © 2020 <br/> Developer:  Kim Semenza  (<a href='mailto:ksemenza.247@gmail.com' className='footer-email'>ksemenza.247@gmail.com</a>) </p>
      
        </div>
        
    )
}

export default Footer