import React from "react";
import RestApp from "../assests/img/restaruantPass.png";
import Todo from "../assests/img/todoList.png";
import RickMorty from "../assests/img/rick.png";
import GithubImg from "../assests/img/githubImg.png";
import JokeList from "../assests/img/joke-api.png";
import Conway from '../assests/img/gol-js.png'
import "../assests/css/Projects.css";

const Projects = () => {
  const newClick = () => {
    window.location.reload();
  };

  return (
    <div>
      <div className="project-ctr">
        <h2> Projects </h2>
        <div className="card-ctr">
          <div className="two-card-ctr">
            {/* PROJECT CONWAY GAME OF LIFE*/}
            <div className="project-grid-cta">
              <div className="title-btn-wrap">
                {/* <div className='btn-ctr '> */}

                <a
                  className="project-name-a"
                  href="https://conway-life-game-js-git-master.ksemenza.vercel.app/"
                  target="blank"
                >
                  {" "}
                  <h3>Conway's Game of Life</h3>{" "}
                </a>
                <a
                  href="https://conway-life-game-js-git-master.ksemenza.vercel.app/"
                  target="blank"
                >
                  <button className="btn demo-btn">Demo</button>
                </a>

                {/* </div> */}
              </div>

              <img
                src={Conway}
                alt="Conway's Game of Life"
                className="project-img img-todo img-left"
              />
            </div>
            {/*  PROJECT RICK AND MORTY*/}
            <div className="two-card-ctr">
              <div className="project-name-img-wrap card-filp">
                <div className="title-btn-wrap">
                  <a
                    className="project-name-a"
                    href="https://rick-morty-fan-spa-git-master.ksemenza.vercel.app/"
                    target="_blank"
                  >
                    {" "}
                    <h3>Rick and Morty Fan page</h3>{" "}
                  </a>

                  <a
                    href="https://rick-morty-fan-spa-git-master.ksemenza.vercel.app/"
                    target="blank"
                  >
                    <button className="btn demo-btn">Demo</button>
                  </a>
                </div>
                <img
                  src={RickMorty}
                  alt="Rick and Morty Fan Page"
                  className="project-img rick-img img-left"
                />
              </div>

              {/*  PROJECT GITHUB USERS CARD*/}
              <div className="project-name-img-wrap card-filp">
                <div className="title-btn-wrap">
                  <a
                    className="project-name-a"
                    href="https://github-users-card.vercel.app/"
                    target="blank"
                  >
                    {" "}
                    <h3>Github User Account Profile</h3>
                  </a>

                  <a
                    href="https://github-users-card.vercel.app/"
                    target="blank"
                  >
                    <button className="btn demo-btn">Demo</button>
                  </a>
                </div>
                <img
                  src={GithubImg}
                  className="project-img img-github img-right"
                  alt="Github User Account"
                />
              </div>

              {/*  PROJECT JOKE API*/}
              <div className="project-name-img-wrap card-filp">
                <div className="title-btn-wrap">
                  <a
                    className="project-name-a"
                    href="https://joke-list-redux-api-git-master.ksemenza.vercel.app/"
                    target="blank"
                  >
                    {" "}
                    <h3>Joke List API</h3>
                  </a>

                  <a
                    href="https://joke-list-redux-api-git-master.ksemenza.vercel.app/"
                    target="blank"
                  >
                    <button className="btn demo-btn">Demo</button>
                  </a>
                </div>
                <img
                  src={JokeList}
                  className="project-img img-github img-right"
                  alt="Github User Account"
                />
              </div>

              {/*  PROJECT TODO*/}
              <div className="project-name-img-wrap card-filp">
                <div className="title-btn-wrap">
                  <a
                    className="project-name-a"
                    href="https://todo-reducer-nine.vercel.app/"
                    target="blank"
                  >
                    {" "}
                    <h3>To Do List</h3>{" "}
                  </a>
                  <a
                    href="https://todo-reducer-nine.vercel.app/"
                    target="blank"
                  >
                    <button className="btn demo-btn">Demo</button>
                  </a>
                </div>
                <img
                  className="project-img img-todo img-right"
                  src={Todo}
                  alt="To Do List"
                />
              </div>
            </div>
          </div>

          {/*PROJECT RESTAURANT */}
          <div className="project-grid-cta">
            <div className="title-btn-wrap">
              {/* <div className='btn-ctr '> */}

              <a
                className="project-name-a"
                href="https://ui-git-master.ksemenza.now.sh/"
                target="blank"
              >
                {" "}
                <h3>Restaurant Passport UI</h3>{" "}
              </a>
              <a href="https://ui-git-master.ksemenza.now.sh/" target="blank">
                <button className="btn demo-btn">Demo</button>
              </a>

              {/* </div> */}
            </div>

            <img
              src={RestApp}
              alt="Restaurant Passport UI"
              className="project-img img-todo img-left"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
