import React, { useState } from 'react'
import {
    NavLink
} from 'react-router-dom'
import PDFViewer from '../utlis/pdfViewer'
import ResumePDF from '../assests/kim-semenza-resume.pdf'
import PdfFormat from '../utlis/pdfFormat'
import LinkedInLogo from '../assests/img/linkedin.png'
import GithubLogo from '../assests/img/github.png'
import EmailLogo from '../assests/img/send-email.png'
import '../assests/css/Resume.css'

const Resume = () => {


    const [pdf, setPDF] = useState(false)

    const handleClick = () => {
        setPDF(!pdf)
    }

    return (
        <div className='resume-cta'>
    
            <button className="pdf_btn" onClick={handleClick}>{!pdf? 'Resume' : 'Back'}</button>
            {pdf ? <PDFViewer backend={PdfFormat} src={ResumePDF} /> : 
                <div>
                                  


                    <div className='summary-about'>
                          <h3 className='modal-title'>Summary</h3>
   
       Motivated and enthusiastic Full
       Stack Developer with skills in UI
       design, dynamic state
       management technologies, and
       relational database management
       systems. Seeks entry level Software
       Developer’ s position.Previous
       projects have included but not
       limited to CRUD operations,
       JWT security, form validation,
       and single page application.
                </div>
         

                <div>
                    <h3 className='about-project'>Projects</h3>
                    <div className='about-link-wrap'>
<a href='https://conway-life-game-js-git-master.ksemenza.vercel.app/' target='blank' className='about-projects-link'>Conway's Game of Life</a>
<br/>
<a href='https://todo-reducer-git-master.ksemenza.vercel.app/' target='blank' className='about-projects-link'>Todo Check List</a>               
<br/>
<a href='https://rick-morty-fan-spa-git-master.ksemenza.vercel.app/' target='blank' className='about-projects-link'>Rick and Morty Fan Page</a>               
<br/>
<a href='https://github-users-card-git-master.ksemenza.vercel.app/' target='blank' className='about-projects-link'>GitHub User Card Search and API Call</a>               
<br/>
</div>
 </div>
                    <h3 className='about-name'> Kim Semenza < br /> Full Stack Web Developer </h3>
                                             <div>
                  
                        <p className='about-contact'><span>  <a href="mailto:ksemenza.247@gmail.com"> ksemenza.247@gmail.com</a></span>
                  <br />
                  <span><a href="tel:1-781-866-1073">(781)866-1073</a></span><br />
                               <div className='social-links-wrap'>
        <a href='https://www.linkedin.com/in/kim-semenza-2b4961199/' target='blank'> 
            <img className='nav-icon linkedin' src={LinkedInLogo} alt='LinkedIn Icon'/></a>
        <a href='https://github.com/ksemenza?tab=repositories' target='blank'>
            <img className='nav-icon github' src={GithubLogo} alt='Github Icon'/></a>
            <a href='mailto:ksemenza.247@gmail.com'> 
            <img className='nav-icon email' src={EmailLogo} alt=' Icon'/></a>
        </div>
                        </p>
                       
                    </div>
                    
                    </div>
            }

     </div>
    )
}

export default Resume